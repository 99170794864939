import "../styles/home.css";

const RedBanner = () => {
  return (
    <div className="redBanner">
      <h2>The cheapest rates in town</h2>
    </div>
  );
};

export default RedBanner;
